// Developed by Explorelogy
import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import BackgroundImage from "gatsby-background-image"
import { convertToBgImage } from "gbimage-bridge"
import Fade from "react-reveal/Fade"
import Zoom from "react-reveal/Zoom"
import MainSlider from "../../components/common/main-slider"
import {
  ChevronDoubleLeftIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/outline"

import textile_white from "../../images/icons/textile_white.svg"
import intergrate_white from "../../images/icons/intergrate_white.svg"
import advance_white from "../../images/icons/advance_white.svg"

import textile_mint from "../../images/icons/textile_mint.svg"
import intergrate_mint from "../../images/icons/intergrate_mint.svg"
import advance_mint from "../../images/icons/advance_mint.svg"

//import svgs for how it works icons
import machinewashable from "../../images/textile-circuitry/platform-icons/machinewashable.svg"
import machinewashablewhite from "../../images/textile-circuitry/platform-icons/machinewashablewhite.svg"
import safe from "../../images/textile-circuitry/platform-icons/safe.svg"
import safewhite from "../../images/textile-circuitry/platform-icons/safewhite.svg"
import seamless from "../../images/textile-circuitry/platform-icons/seamless.svg"
import seamlesswhite from "../../images/textile-circuitry/platform-icons/seamlesswhite.svg"

//icons for how it works
import Composition from "../../images/flexible-battery/how-it-works-icons/Composition.svg"
import Construction from "../../images/flexible-battery/how-it-works-icons/Construction.svg"
import Power from "../../images/flexible-battery/how-it-works-icons/Power.svg"

import Functonalwhite from "../../images/thermal-regulation/icons/Functionalwhite.svg"
// import Machinewashablegreen from "../../images/thermal-regulation/icons/Machinewashablegreen.svg"

// import optionalitywhite from "../..//images/thermal-regulation/icons/optionalitywhite.svg"
import Failsafewhite from "../../images/thermal-regulation/icons/Failsafewhite.svg"

//import platform slider

const TextileCircuitry = ({ data }) => {
  const images = {
    dusk_texure: convertToBgImage(getImage(data.dusk_texure)),
    hero: convertToBgImage(getImage(data.hero)),
    baner_odlo: convertToBgImage(getImage(data.baner_odlo)),

    led: convertToBgImage(getImage(data.led)),
    a: getImage(data.grid_1),
    grid_1: convertToBgImage(getImage(data.grid_1)),
    grid_2: convertToBgImage(getImage(data.grid_2)),
    grid_3: convertToBgImage(getImage(data.grid_3)),
    grid_4: convertToBgImage(getImage(data.grid_4)),
    grid_5: convertToBgImage(getImage(data.grid_5)),
    salmon_texture: convertToBgImage(getImage(data.salmon_texture)),
    slider_1: convertToBgImage(getImage(data.slider_1)),
    slider_2: convertToBgImage(getImage(data.slider_2)),
    slider_3: convertToBgImage(getImage(data.slider_3)),

    //application images

    Connected: convertToBgImage(getImage(data.Connected)),
    Healthcare: convertToBgImage(getImage(data.Healthcare)),

    //how it works images

    Composition: convertToBgImage(getImage(data.Composition)),
    Construction: convertToBgImage(getImage(data.Construction)),
    Powering: convertToBgImage(getImage(data.Powering)),

    //capability images
    configure_img: convertToBgImage(getImage(data.configure_img)),
    tech_spec_img: convertToBgImage(getImage(data.tech_spec_img)),

    //consumer img
    conductivepathways: convertToBgImage(getImage(data.conductivepathways)),
    Intercoonects: convertToBgImage(getImage(data.Intercoonects)),
    Conductive: convertToBgImage(getImage(data.Conductive)),
    Encapsulation: convertToBgImage(getImage(data.Encapsulation)),

    //intergrated carousel images

    visiblity_img: convertToBgImage(getImage(data.inter_img1)),
    data_img: convertToBgImage(getImage(data.inter_img2)),
    feedback_img: convertToBgImage(getImage(data.inter_img3)),
    flexible_battery_img: convertToBgImage(getImage(data.inter_img4)),
    heating_img: convertToBgImage(getImage(data.inter_img5)),
    sensing_img: convertToBgImage(getImage(data.inter_img6)),
    textile_circuitry_img: convertToBgImage(getImage(data.inter_img7)),
    touch_textile_img: convertToBgImage(getImage(data.inter_img8)),

    //advance material images

    accoustic_img: convertToBgImage(getImage(data.advance_img1)),
    bio_mimikry_img: convertToBgImage(getImage(data.advance_img2)),
    memory_foam_img: convertToBgImage(getImage(data.advance_img3)),
    pu_foam_img: convertToBgImage(getImage(data.advance_img4)),
    silicon_print_img: convertToBgImage(getImage(data.advance_img5)),
    silicon_img: convertToBgImage(getImage(data.advance_img6)),

    // textile engineering images

    engineered_knit_img: convertToBgImage(getImage(data.textile_img1)),
    inj_mold_img: convertToBgImage(getImage(data.textile_img2)),
    narrow_width_img: convertToBgImage(getImage(data.textile_img3)),
  }

  const [activePlatform, setActivePlatform] = useState(0)
  const [activePlatform2, setActivePlatform2] = useState(0)
  const [active, setActive] = useState(0)

  const slider = [
    {
      title: "Engineered Knit",
      content:
        "Leveraging Flat, Circular, and Warp knitting techniques to create complex structures, designs, and modulus.",
      image: images.engineered_knit_img,
      link: "/textile-engineering",
    },
    {
      title: "Narrow Width Fabrics",
      content:
        "Elasticated fabrics and elastomeric yarns engineered to achieve stretch and durability, as well as support complex design and construction.",
      image: images.narrow_width_img,
      link: "/textile-engineering",
    },
    {
      title: "Injection Molding",
      content:
        "A revolutionary form and padding technology for impact protection and the encasing of electronics.",
      image: images.inj_mold_img,
      link: "/textile-engineering",
    },
  ]

  const slider2 = [
    {
      title: "Visibility ",
      content:
        " Next-to-skin LED-based lighting that provides on-demand illumination ensuring visibility and safety, now with RGB capabilities.",
      image: images.visiblity_img,
      link: "/integrated-technologies",
    },
    {
      title: "Thermal Regulation",
      content:
        "Intelligent fabric-based thermal regulation, that can support a variety of functions ranging from microclimate regulation to pain management.",
      image: images.heating_img,
      link: "/integrated-technologies",
    },
    {
      title: "Sensing",
      content:
        "Seamlessly integrated biopotential and biomechanical sensors that allow for the collection of metrics ranging from heart rate to motion.",
      image: images.sensing_img,
      link: "/integrated-technologies",
    },
    {
      title: "Feedback",
      content:
        " Haptics, TENS, and EMS integrated into soft goods for tactile feedback, notification, and chronic pain management.",
      image: images.feedback_img,
      link: "/integrated-technologies",
    },
    {
      title: "Touch Textiles",
      content:
        " Pressure-sensitive textile constructions used to create interactive interfaces, replacing the need for traditional plastic components.",
      image: images.touch_textile_img,
      link: "/integrated-technologies",
    },
    {
      title: "Data Transfer",
      content:
        " Transfer of data and power across fabric substrates with limited flow and interference and low resistance, supporting over 4gbps and 40V at 5A.",
      image: images.data_img,
      link: "/integrated-technologies",
    },
    {
      title: "Flexible Battery",
      content:
        " Adaptable battery for a multitude of wearable uses. Flexible, washable, semi-solid electrolyte construction that serves as the industry’s solution to the growing design needs.",
      image: images.flexible_battery_img,
      link: "/integrated-technologies",
    },

    {
      title: "Textile Circuitry",
      content:
        " A collection of conductive pathways and inlays, interconnects and encapsulation to present technology in a seamless and invisible manner",
      image: images.textile_circuitry_img,
      link: "/integrated-technologies",
    },
  ]

  const slider3 = [
    {
      title: "Bio-mimicking material",
      content:
        "Thermo-responsive polymers and yarns, pine-cone biomimetic yarn for moisture management for improved comfort over extended wear times.",
      image: images.bio_mimikry_img,
      link: "/advanced-materials",
    },
    {
      title: "PU foam-based Injection Molding",
      content:
        "Replacing hard plastic components for flexibility, durability, and seamless integration of sensors",
      image: images.pu_foam_img,
      link: "/advanced-materials",
    },
    {
      title: "Acoustic dampening fabrics",
      content:
        "Certified Acoustic fabrics specifically designed for uninterrupted sound passthrough from materials to product.",
      image: images.accoustic_img,
      link: "/advanced-materials",
    },
    {
      title: "Memory Foam with PU Leather",
      content:
        "Internal and external fabric/leather lamination on foam provides superior comfort and flexibility. Memory foam built for high impact/low frequency.",
      image: images.memory_foam_img,
      link: "/advanced-materials",
    },

    {
      title: "Silicone Printing and Embellishments",
      content:
        "Patented Silicone Application Technology to enable targeted modification of fabric modulus using engineered functional prints for grip and comfort",
      image: images.silicon_print_img,
      link: "/advanced-materials",
    },
  ]

  const howItWorks = [
    {
      title: "Construction",
      content: "semi-solid electrolyte",
      image: Construction,
      cover: images.Construction,
    },
    {
      title: "Power",
      content: "3.7v 250mAh",
      image: Power,
      cover: images.Powering,
    },
    {
      title: "Composition",
      content:
        "Specially formulated chemical composition and packaging enable the battery to be truly flexible.",
      image: Composition,
      cover: images.Composition,
    },
  ]

  function hello() {
    console.log(howItWorks[active])
  }

  const platform = [
    {
      title: "Technology Specifications",
      content: "Seamless integration - ",
      content2:
        "Currently ready to integrate with all soft matter platforms (with the exception for high power thermoregulation application)",
      content3:
        "Most conventional power applications can be replaced with a flexible variant.  Consideration of capacity will be the key determining factor.",

      image: "../../images/brand/symbol-mint.png",
      cover: images.tech_spec_img,
    },
    {
      title: "Configurations",
      content:
        "Currently available in a single form factor of 2x6 inches. However, consumer packaging and capacities maybe developed based on application.",
      content2:
        "Machine washability means that the device can power connected devices and other softgood without any special care instructions",
      image: "../../images/brand/symbol-mint.png",
      cover: images.configure_img,
    },
  ]

  const platformSlider = [
    {
      title: "Textile Engineering",
      image: textile_white,
      image_active: textile_mint,
    },
    {
      title: "Integrated Technologies",
      image: intergrate_white,
      image_active: intergrate_mint,
    },
    {
      title: "Advanced Material",
      image: advance_white,
      image_active: advance_mint,
    },
  ]

  return (
    <Layout>
      <Seo title="Textile Circuitry" />
      <section>
        <BackgroundImage {...images.hero} className="h-screen bg-dusk">
          <div className="container h-full mx-auto">
            <div className="flex h-full items-center">
              <Fade bottom cascade>
                <div className="flex flex-col ">
                  <div className="sm:text-6xl text-4xl  sm:text-left text-center  mb-1 text-white">
                    Textile Circuitry
                  </div>
                  <div className="sm:text-2xl text-xl sm:max-w-lg text-white font-light mt-4  sm:text-left text-center">
                    Integrating technology in a seamless,
                    <div>invisible manner</div>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </BackgroundImage>
      </section>
      <section>
        <div className="bg-chalk">
          <div className="container mx-auto py-20 text-center sm:text-left">
            <div className="flex flex-col items-center">
              <Fade bottom cascade>
                <div>
                  <div className="text-salmon mb-4 uppercase text-center ">
                    ELEVATING PRODUCT APPLICATIONS
                  </div>
                  <div className="text-4xl text-center font-light mb-4 text-dusk">
                    Invisible and Seamless
                  </div>
                  {/* <div className="text-2xl font-light text-center max-w-5xl leading-snug text-dusk">
                    Our groundbreaking textile-integrated illumination
                    technology that is revolutionizing the future of apparel,
                    setting new standards for safety, visibility, and product
                    differentiation. Our platform offers more than 450 feet of
                    visibility in low light conditions with over 8 hours of
                    continuous illumination.
                  </div> */}
                  <div className="text-2xl font-light text-center max-w-6xl leading-snug mt-4 text-dusk">
                    Our solutions in textile circuitry offer a range of novel
                    capabilities allowing for a new generation of devices that
                    are soft, stretchable and anatomically compliant, and
                    withstanding high tensile stress associated with on-body
                    applications.
                  </div>
                </div>
              </Fade>
              <div className="mt-24">
                <Fade bottom>
                  <div className="text-4xl text-center font-light mb-4 text-dusk">
                    Platform Features
                  </div>
                </Fade>
                <Fade bottom cascade>
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-8 text-dusk">
                    <div className="border border-salmon p-5 pb-10 group hover:bg-salmon duration-200 hover:text-white">
                      <div className="flex sm:justify-start justify-center">
                        <img
                          src={seamless}
                          width={80}
                          alt="Softmatter"
                          className="group-hover:hidden block cursor-pointer my-3"
                        />
                        <img
                          src={seamlesswhite}
                          width={80}
                          alt="Softmatter"
                          className="cursor-pointer my-3 group-hover:block hidden"
                        />
                      </div>
                      <div className="text-xl font-light font-firs-regular border-b border-salmon text-dusk group-hover:text-white duration-200 group-hover:border-chalk pb-4 max-w-5xl leading-snug mt-4">
                        Seamless
                      </div>
                      <div className="text-base font-light max-w-5xl leading-snug mt-4">
                        Design freedom without sacrificing performance or
                        comfort.
                      </div>
                    </div>
                    <div className="border border-salmon p-5 pb-10 group hover:bg-salmon duration-200 hover:text-white">
                      <div className="flex sm:justify-start justify-center">
                        <img
                          src={safe}
                          width={80}
                          alt="Softmatter"
                          className="group-hover:hidden block cursor-pointer my-3"
                        />
                        <img
                          src={safewhite}
                          width={80}
                          alt="Softmatter"
                          className="cursor-pointer my-3 group-hover:block hidden"
                        />
                      </div>
                      <div className="text-xl font-light font-firs-regular border-b border-salmon text-dusk group-hover:text-white duration-200 group-hover:border-chalk pb-4 max-w-5xl leading-snug mt-4">
                        Safe
                      </div>
                      <div className="text-base font-light max-w-5xl leading-snug mt-4">
                        Ensures safety and withstands mechanical stresses.
                      </div>
                    </div>
                    <div className="border border-salmon p-5 pb-10 group hover:bg-salmon duration-200 hover:text-white">
                      <div className="flex sm:justify-start justify-center">
                        <img
                          src={machinewashable}
                          width={80}
                          alt="Softmatter"
                          className="group-hover:hidden block cursor-pointer my-3"
                        />
                        <img
                          src={machinewashablewhite}
                          width={80}
                          alt="Softmatter"
                          className="cursor-pointer my-3 group-hover:block hidden"
                        />
                      </div>
                      <div className="text-xl font-light font-firs-regular border-b border-salmon text-dusk group-hover:text-white duration-200 group-hover:border-chalk pb-4 max-w-5xl leading-snug mt-4">
                        Machine Washable
                      </div>
                      <div className="text-base font-light max-w-5xl leading-snug mt-4">
                        Machine washable systems.
                      </div>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="bg-salmon overflow-hidden">
          <div className="flex lg:flex-row flex-col">
            <div className="w-full lg:w-1/2 lg:hidden block">
              <Fade right>
                <div className="w-full ">
                  <BackgroundImage
                    {...images.conductivepathways}
                    className="xl:py-80 md:py-96 py-52"
                  ></BackgroundImage>
                </div>
              </Fade>
            </div>
            <div className="lg:w-1/2 w-full py-20 lg:py-0 pl-5 sm:pl-20 xl:pl-36 flex flex-col justify-center">
              <Fade bottom cascade>
                <div className="text-center sm:text-left pr-5 sm:pr-10 xl:pr-20">
                  <div className="text-4xl font-light mb-4 text-chalk ">
                    Conductive Pathways
                  </div>
                  <div className="text-xl font-light max-w-5xl leading-snug text-white">
                    Being the backbone of textile circuitry, conductive pathways
                    offer the means to connect power and data. Available on
                    multiple different configurations (16+ variants) to suit
                    different applications.
                  </div>
                  <div className="text-xl font-light max-w-5xl leading-snug mt-8 text-dusk">
                    <div className="mb-2">+ Conductive Coated Wires</div>
                    <div className="mb-2">+ Conductive Yarn</div>
                    <div className="mb-2">+ Printed Pathways</div>
                    <div className="mb-2">+ Sewable Pathways</div>
                    <div className="mb-2">+ Embroidered Pathways</div>
                  </div>
                  {/* <div className="text-xl font-light max-w-5xl leading-snug mt-4 text-white">
                    As this technology has matured, it has seen increased use in
                    thermotherapy wearables targeted at pain relief and
                    soreness.
                  </div> */}
                </div>
              </Fade>
            </div>
            <div className="w-full lg:w-1/2 lg:block hidden">
              <Fade right>
                <div className="w-full ">
                  <BackgroundImage
                    {...images.conductivepathways}
                    className="xl:py-80 md:py-96 py-52"
                  ></BackgroundImage>
                </div>
              </Fade>
            </div>
          </div>
          <div className="flex lg:flex-row flex-col bg-chalk">
            <div className="w-full lg:w-1/2">
              <Fade left>
                <div className="w-full ">
                  <BackgroundImage
                    {...images.Intercoonects}
                    className="xl:py-80 md:py-96 py-52"
                  ></BackgroundImage>
                </div>
              </Fade>
            </div>
            <div className="lg:w-1/2  w-full py-20 lg:py-0 pl-5 sm:pl-20 xl:pl-36 flex flex-col justify-center">
              <Fade bottom cascade>
                <div className="text-center sm:text-left pr-5 sm:pr-10 xl:pr-20">
                  <div className="text-4xl font-light mb-4 text-salmon ">
                    Interconnections
                  </div>
                  <div className="text-xl font-light max-w-5xl leading-snug text-dusk">
                    Techniques that have been developed to connect both external
                    and embedded electronic accessories to textile circuitries
                  </div>
                  <div className="text-xl font-light max-w-5xl leading-snug mt-8 text-salmon">
                    <div className="mb-2">+ Conductive Adhesives</div>
                    <div className="mb-2">+ Bar Tacking</div>
                    <div className="mb-2">+ Rivets</div>
                    <div className="mb-2">+ Snaps: Magnetic or Mechanical</div>
                    <div className="mb-2">+ Crimp Pins</div>
                    <div className="mb-2">+ FPC FFC Connectors</div>
                  </div>
                  {/* <div className="text-xl font-light max-w-5xl leading-snug mt-4 text-white">
                    As this technology has matured, it has seen increased use in
                    thermotherapy wearables targeted at pain relief and
                    soreness.
                  </div> */}
                </div>
              </Fade>
            </div>
          </div>
          <div className="flex lg:flex-row flex-col">
            <div className="w-full lg:w-1/2 lg:hidden block">
              <Fade right>
                <div className="w-full ">
                  <BackgroundImage
                    {...images.Intercoonects}
                    className="xl:py-80 md:py-96 py-52"
                  ></BackgroundImage>
                </div>
              </Fade>
            </div>
            <div className="lg:w-1/2 w-full py-20 lg:py-0 pl-5 sm:pl-20 xl:pl-36 flex flex-col justify-center">
              <Fade bottom cascade>
                <div className="text-center sm:text-left pr-5 sm:pr-10 xl:pr-20">
                  <div className="text-4xl font-light mb-4 text-chalk ">
                    Conductive Inlays
                  </div>
                  <div className="text-xl font-light max-w-5xl leading-snug text-white">
                    The capability of incorporating flexible wires, tubes, and
                    active elements to the fabric by using the knitting process
                    to present the technology in a seamless, invisible manner
                  </div>
                  <div className="text-xl font-light max-w-5xl leading-snug mt-8 text-dusk">
                    <div className="mb-2">+ Smart garments</div>
                    <div className="mb-2">+ Heating</div>
                    <div className="mb-2">+ Data transferring</div>
                    <div className="mb-2">
                      + Lighting ( optical fiber, LED yarns)
                    </div>
                    <div className="mb-2">+ NFC + RFID (In yarn level)</div>
                    <div className="mb-2">+ Sensor in yarn level</div>
                  </div>
                  {/* <div className="text-xl font-light max-w-5xl leading-snug mt-4 text-white">
                    As this technology has matured, it has seen increased use in
                    thermotherapy wearables targeted at pain relief and
                    soreness.
                  </div> */}
                </div>
              </Fade>
            </div>
            <div className="w-full lg:w-1/2 lg:block hidden">
              <Fade right>
                <div className="w-full ">
                  <BackgroundImage
                    {...images.Conductive}
                    className="xl:py-80 md:py-96 py-52"
                  ></BackgroundImage>
                </div>
              </Fade>
            </div>
          </div>
          <div className="flex lg:flex-row flex-col bg-chalk">
            <div className="w-full lg:w-1/2">
              <Fade left>
                <div className="w-full ">
                  <BackgroundImage
                    {...images.Encapsulation}
                    className="xl:py-80 md:py-96 py-52"
                  ></BackgroundImage>
                </div>
              </Fade>
            </div>
            <div className="lg:w-1/2  w-full py-20 lg:py-0 pl-5 sm:pl-20 xl:pl-36 flex flex-col justify-center">
              <Fade bottom cascade>
                <div className="text-center sm:text-left pr-5 sm:pr-10 xl:pr-20">
                  <div className="text-4xl font-light mb-4 text-salmon ">
                    Encapsulation
                  </div>
                  <div className="text-xl font-light max-w-5xl leading-snug text-dusk">
                    Capability of incorporating flexible wires, tubes and active
                    elements to the fabric by using knitting process to present
                    the technology in a seamless, invisible manner
                  </div>
                  <div className="text-xl font-light max-w-5xl leading-snug mt-8 text-salmon">
                    <div className="mb-2">+ PastiDip</div>
                    <div className="mb-2">+ Silicone</div>
                    {/* <div className="mb-2">+ Rivets</div> */}
                    <div className="mb-2">+ Liquid PU</div>
                    <div className="mb-2">+ PU Tapes</div>
                    <div className="mb-2">+ Neoprene + Fabric</div>
                  </div>
                  {/* <div className="text-xl font-light max-w-5xl leading-snug mt-4 text-white">
                    As this technology has matured, it has seen increased use in
                    thermotherapy wearables targeted at pain relief and
                    soreness.
                  </div> */}
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="bg-dusk">
          <div className=" pt-24">
            <Fade bottom cascade>
              <div className="text-white px-5">
                <div className="text-center text-2xl font-firs-thin uppercase  mb-4">
                  OUR TEXTILE CIRCUITRY
                </div>
                <div className="text-center text-5xl md:text-6xl mb-4">
                  Applications
                </div>
                <div className="text-2xl mt-2 text-center max-w-4xl mx-auto">
                  Embedding Intelligence through textile circuitry.
                </div>
              </div>
            </Fade>
            <div className="mt-32 overflow-hidden">
              <div className="flex lg:flex-row flex-col ">
                <div className="w-full lg:hidden block">
                  <Fade right>
                    <div className="w-full ">
                      <BackgroundImage
                        {...images.Intercoonects}
                        className="py-72 "
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
                <div className="lg:w-1/2 w-full lg:pl-36 pl-10 flex flex-col justify-center">
                  <Fade>
                    <div className="text-white pr-10 sm:text-left text-center lg:py-0 py-10">
                      <div className="text-4xl font-light mb-3 leading-tight">
                        Interconnections
                      </div>
                      <div className="text-base max-w-xl">
                        <div className="mt-8 text-white">
                          <div className="mb-2">
                            + For Durability and withstanding mechanical
                            stresses
                          </div>
                          <div className="mb-2">
                            + No data loss with negligible resistance in
                            connection
                          </div>
                          <div className="mb-2">+ Machine washable</div>
                        </div>
                        <div className="mt-8 text-salmon">
                          <div className="text-white mb-2">Allows for:</div>
                          <div className="mb-2">
                            + From sensor interfaces to conductive pathways to
                            control unit
                          </div>
                          <div className="mb-2">
                            + Between textile circuitries at different locations
                          </div>
                          <div className="mb-2">
                            + From textile circuitries to batteries
                          </div>
                        </div>
                      </div>
                      <Link to="/contact">
                        <button className="uppercase px-4 py-3 border border-salmon rounded-full text-salmon mt-6 hover:bg-salmon/20 cursor-pointer duration-200">
                          Request Information
                        </button>
                      </Link>
                    </div>
                  </Fade>
                </div>
                <div className="w-1/2 lg:block hidden">
                  <Fade right>
                    <div className="w-full ">
                      <BackgroundImage
                        {...images.Intercoonects}
                        className="py-72 "
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="flex lg:flex-row flex-col ">
                <div className="w-full lg:w-1/2">
                  <Fade left>
                    <div className="w-full">
                      <BackgroundImage
                        {...images.Conductive}
                        className="py-72 "
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
                <div className="lg:w-1/2 w-full lg:pl-36 pl-10 flex flex-col justify-center">
                  <Fade>
                    <div className="text-white pr-10 sm:text-left text-center lg:py-0 py-10">
                      <div className="text-4xl font-light mb-3 leading-tight">
                        Conductive Inlays
                      </div>
                      <div className="text-base max-w-xl">
                        <div className="mt-8 text-white">
                          <div className="mb-2">
                            + Ubiquitous and invisible integration with less
                            material handling
                          </div>
                          <div className="mb-2">
                            +Automated process (No separate insertion) with
                            time/cost savings
                          </div>
                        </div>
                        <div className="mt-8 text-salmon">
                          <div className="mb-2">+ Connected Clothing</div>
                          <div className="mb-2">
                            + Varied technology integration capability :
                            Heating, Data transferring, Lighting ( optical
                            fiber, LED yarns), NFC + RFID (In yarn level),
                            Sensor
                          </div>
                          <div className="mb-2">+ Built in at yarn level</div>
                        </div>
                      </div>
                      <Link to="/contact">
                        <button className="uppercase px-4 py-3 border border-salmon rounded-full text-salmon mt-6 hover:bg-salmon/20 cursor-pointer duration-200">
                          Request Information
                        </button>
                      </Link>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="bg-dusk py-24 overflow-hidden relative">
          <div className="container mx-auto">
            <div className="mb-20">
              <Fade top>
                <div className="text-center text-2xl font-firs-thin text-white mb-4">
                  TECHNOLOGY WOVEN IN
                </div>
              </Fade>
              <Fade bottom>
                <div className="text-center text-4xl text-white">
                  Explore All Platforms
                </div>
              </Fade>
            </div>
            <div className="flex lg:flex-row flex-col mb-72 lg:mb-44">
              <div className="w-full lg:w-4/12">
                <div className="flex gap-5 lg:gap-10 lg:flex-col justify-center flex-row">
                  {platformSlider.map((item, index) => {
                    return (
                      <Fade bottom cascade key={index}>
                        <div className=" text-white mb-14">
                          <div className="uppercase text-xl lg:block flex justify-center font-light mb-4">
                            {activePlatform == index ? (
                              <>
                                <img
                                  src={item.image_active}
                                  width={60}
                                  alt="Softmatter"
                                  className="cursor-pointer sm:w-16 w-10"
                                  onClick={() => setActivePlatform(index)}
                                />
                              </>
                            ) : (
                              <>
                                <img
                                  src={item.image}
                                  width={60}
                                  alt="Softmatter"
                                  className="cursor-pointer sm:w-16 w-10"
                                  onClick={() => setActivePlatform(index)}
                                />
                              </>
                            )}
                          </div>
                          <div
                            className={
                              activePlatform == index
                                ? "mt-6  text-xs sm:text-xl text max-w-sm text-mint duration-100  lg:text-left text-center"
                                : "mt-6  text-xs sm:text-xl text max-w-sm duration-100 lg:text-left text-center"
                            }
                          >
                            <span
                              className={
                                activePlatform == index
                                  ? "pb-2 border-b-0 lg:border-b-2 border-mint duration-100 cursor-pointer"
                                  : "pb-2 duration-100 cursor-pointer hover:border-b-2 border-white"
                              }
                              onClick={() => setActivePlatform(index)}
                            >
                              {item.title}
                            </span>
                          </div>
                        </div>
                      </Fade>
                    )
                  })}
                </div>
              </div>
              <div className="w-full lg:w-7/12 pb-96 mb-32 sm:mb-24 lg:mb-0 lg:pb-24">
                <div className="text-white absolute w-full">
                  {activePlatform == 0 && (
                    <>
                      <MainSlider slider_array={slider} hover />
                    </>
                  )}
                  {activePlatform == 1 && (
                    <>
                      <MainSlider slider_array={slider2} hover />
                    </>
                  )}
                  {activePlatform == 2 && (
                    <>
                      <MainSlider slider_array={slider3} hover />
                    </>
                  )}

                  <div className="flex pr-0 sm:pr-20 lg:pr-96 mr-0 sm:mr-20 md:mr-60 xl:mr-80 2xl:mr-96 justify-center items-center mt-14">
                    <div className="text-white my-custom-pagination-div ml-0 xl:ml-16 2xl:ml-24"></div>
                    <div className="flex justify-center mr-20">
                      <div className="swiper-button-prev-square py-3 px-4 cursor-pointer flex items-center justify-center bg-black/50 text-white hover:bg-white hover:text-dusk duration-200">
                        <ChevronLeftIcon width={20} />
                      </div>
                      <div className="swiper-button-next-square py-3 px-4 cursor-pointer bg-black/50 text-white hover:bg-white hover:text-dusk duration-200">
                        <ChevronRightIcon width={20} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="flex lg:flex-row flex-col">
          <div className="w-full lg:w-1/2">
            <BackgroundImage
              {...images.salmon_texture}
              className="h-full bg-cover lg:py-0 py-40"
            >
              <Zoom>
                <div className="flex h-full items-center justify-center">
                  <StaticImage
                    src="../images/brand/logo-symbol.png"
                    placeholder="blurred"
                    alt="Softmatter"
                    width={180}
                    className="lg:w-auto w-20"
                  />
                </div>
              </Zoom>
            </BackgroundImage>
          </div>
          <div className="w-full lg:w-1/2">
            <Fade bottom cascade>
              <div className="bg-chalk py-20 lg:py-56 text-dusk px-10">
                <div className="text-center  text-5xl sm:text-6xl mb-4">
                  How to Engage
                </div>
                <div className="text-xl mt-2 text-center max-w-xl mx-auto">
                  At Softmatter, our dynamic team has industry-leading expertise
                  in smart textiles, from design to manufacture. We help you
                  strategically enhance your offering so that it has a profound,
                  global impact.
                </div>
                <div className="text-2xl mt-5 text-center max-w-4xl mx-auto">
                  Interested in enabling your idea?
                </div>
                <div className="flex justify-center mt-8">
                  <Link to="/contact">
                    <button className="px-8 py-4 bg-salmon  hover:bg-salmon/90 duration-200 text-white text-2xl">
                      Contact Us
                    </button>
                  </Link>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query TextileCircuitryImages {
    dusk_texure: file(relativePath: { eq: "textures/dusk_texure.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    hero: file(relativePath: { eq: "textile-circuitry/hero.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    conductivepathways: file(
      relativePath: { eq: "textile-circuitry/conductivepathways.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    Intercoonects: file(
      relativePath: { eq: "textile-circuitry/Intercoonects.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    Encapsulation: file(
      relativePath: { eq: "textile-circuitry/Encapsulation.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    Conductive: file(relativePath: { eq: "textile-circuitry/Conductive.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    baner_odlo: file(
      relativePath: { eq: "thermal-regulation/baner_odlo.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    salmon_texture: file(relativePath: { eq: "textures/salmon_texture.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    slider_1: file(relativePath: { eq: "home/slider/slider_1.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    slider_2: file(relativePath: { eq: "home/slider/slider_2.jpeg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    slider_3: file(relativePath: { eq: "home/slider/slider_3.jpeg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    patented_awarded: file(
      relativePath: { eq: "visibility/Patentetd_awarded.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    app2: file(
      relativePath: { eq: "thermal-regulation/applications/Recovery.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    capability_img2: file(
      relativePath: {
        eq: "thermal-regulation/Platform-Capabilities/ResearchThermal.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img1: file(
      relativePath: { eq: "platform-carousel/Integrated/Visibility.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img2: file(
      relativePath: { eq: "platform-carousel/Integrated/Data.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img3: file(
      relativePath: { eq: "platform-carousel/Integrated/Feedback.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img4: file(
      relativePath: { eq: "platform-carousel/Integrated/Flexible_Battery.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img5: file(
      relativePath: { eq: "platform-carousel/Integrated/Heating.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img6: file(
      relativePath: { eq: "platform-carousel/Integrated/Sensing.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img7: file(
      relativePath: { eq: "platform-carousel/Integrated/Textile_circuitry.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img8: file(
      relativePath: { eq: "platform-carousel/Integrated/Touch_textiles.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    advance_img1: file(
      relativePath: { eq: "platform-carousel/Advanced-Materials/Accoustic.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    advance_img2: file(
      relativePath: {
        eq: "platform-carousel/Advanced-Materials/Bio_Mimikry_Material.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    advance_img3: file(
      relativePath: {
        eq: "platform-carousel/Advanced-Materials/Memory_Foam_with_PU_Leather.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    advance_img4: file(
      relativePath: {
        eq: "platform-carousel/Advanced-Materials/PU_foam_based_Injection_Molding.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    advance_img5: file(
      relativePath: {
        eq: "platform-carousel/Advanced-Materials/Silicone_Printing.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    advance_img6: file(
      relativePath: { eq: "platform-carousel/Advanced-Materials/Silicone.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    main_carousel1: file(
      relativePath: { eq: "Main_category_carousel/Advanced-Material.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    main_carousel2: file(
      relativePath: { eq: "Main_category_carousel/Integrated.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    main_carousel3: file(
      relativePath: { eq: "Main_category_carousel/Textile-Engineering.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    textile_img1: file(
      relativePath: {
        eq: "platform-carousel/Textile-Engineering/Engineered_Knit.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    textile_img2: file(
      relativePath: {
        eq: "platform-carousel/Textile-Engineering/Injection_Molding.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    textile_img3: file(
      relativePath: {
        eq: "platform-carousel/Textile-Engineering/Nawrrow_Width.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
  }
`

export default TextileCircuitry
